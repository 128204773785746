
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: "QuestionnaireError",
  data() {
    return {
      notification: {} as dynamicsObject,
      timeout: 0,
    };
  },
  computed: {
    ...mapGetters(['questionnaireError'])
  },
  watch: {
    "$store.state.questionnaireError": function () {
      if (this.$store.getters.questionnaireError) {
        this.sendReportError();
      } else {
        this.notification = {};
      }
    },
  },
  methods: {
    sendReportError() {
      this.axios
        .post(`/api/questionnaire/save/mode/error`, {
          err: this.questionnaireError.err,
          questionnaire: this.questionnaireError.questionnaire
        })
        .catch(err => {
          if (err?.response?.status === 400 || err?.response?.status === 401) {
            this.$store.commit("createNotification", {
              status: "error",
              message: err.response.data.message,
            });
            return this.$router.push('/auth');
          }
          this.notification = this.questionnaireError;
          this.timeout = setTimeout(() => {
            this.notification = {};
            this.$store.commit("destroyQuestionnaireError");
            clearTimeout(this.timeout);
          }, 10000);
          if (this.questionnaireError.err && this.questionnaireError.err.config && this.questionnaireError.err.config.data) {
            localStorage.setItem('saveMode', JSON.stringify({ questionnaire: this.questionnaireError.questionnaire, data: this.questionnaireError.err.config.data }));
          }
        });
    },
    cancel() {
      clearTimeout(this.timeout);
      this.$store.commit("destroyQuestionnaireError");
    },
  }
});
